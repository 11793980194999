import React, { useState, useEffect } from 'react';

const Grid = (props) => {
  const { data } = props;
  const [allData, setAllData] = useState({});

  useEffect(() => {
    if(data.Contents) {
      let label = data.Contents.find(item => item.Key === "sobre-grid-label");
      let imgList = data.Contents.filter(item => item.Key.includes("sobre-grid-image"));
      imgList = imgList.map(item => item.Value)
      setAllData(allData => ({
        ...allData,
        label: label ? label.Value : "ZL EZATA",
        imgList: imgList ? imgList : null,
      }))
    }
  }, [data])

  return (
    <section id="equipe" className="container-60">
      <div className="def__label__box">
        <div className="rectangle"></div>
        <p className="label">{allData.label}</p>
        <div className="rectangle"></div>
      </div>
      <div className="wrapper-5">
        {allData.imgList ? allData.imgList.map((item, index) => (
          <img key={index} className="img" src={item} alt="Imagem Grid" />
        )) : null}        
      </div>
    </section>
  )
}

export default Grid;